import SubscriptionInvoice from '../models/SubscriptionInvoice';
import { PaymentMethodResponse } from '../schemas/CardSchema';
import { ModelEndpointResponse } from '~/app/base/endpoints/types';
import Tenant from '~/app/auth/models/Tenant';
import ModelFactory from '~/app/factories/ModelFactory';
import { ModelType } from '~/app/base/schemas/BaseSchema';
const runtimeConfig = useRuntimeConfig();

export default class SubscriptionEndpoint {
    getUrl(path: string): string {
        const { tenant } = useAuthStore();
        return `${runtimeConfig.public.worksiteSubscriptionUrl}/api/tenants/${tenant?.getId()}/${path}`;
    }

    getRequestHeaders(): HeadersInit {
        const { usableToken, authed, tenant } = useAuthStore();

        const headers: HeadersInit = new Headers();
        headers.set('Content-Type', 'application/vnd.api+json');
        headers.set('Accept', 'application/vnd.api+json');
        headers.set('Content-Language', authed?.locale || 'en');
        headers.set('Authorization', usableToken);

        return headers;
    }

    async transformTenantResponse(response: any): ModelEndpointResponse<Tenant> {
        const json = await response.json();

        if (!response.ok) {
            const errorResponse: ModelEndpointResponse<Tenant> = {
                data: null,
                response,
                error: json.errors,
                validationErrors: null,
            };

            if (response.status == 422 && json.errors && json.errors.length > 0) {
                // Validation errors
            }

            this.trackEvent('endpoint:exception', { error: errorResponse });

            return errorResponse;
        }

        const data: Tenant = ModelFactory.make(ModelType.TENANTS, json.data, json.included);
        const successResponse: ModelEndpointResponse<Tenant> = {
            response,
            data,
            error: null,
            validationErrors: null,
        };
        return successResponse;
    }

    async updatePlan(args: { plan: string; coupons?: string[] | undefined }): Promise<ModelEndpointResponse<Tenant>> {
        const response = await fetch(this.getUrl('subscriptions/plan'), {
            method: 'PATCH',
            headers: this.getRequestHeaders(),
            body: JSON.stringify(args),
        });
        return await this.transformTenantResponse(response);
    }

    // TBD REPONSE
    async paymentMethodsIndex() {
        try {
            const response = await fetch(this.getUrl('customers/paymentMethods'), {
                method: 'GET',
                headers: this.getRequestHeaders(),
            });
            return (await response.json()) as PaymentMethodResponse;
        } catch (error) {}
    }

    async updateBillingCycle(billingcycle: string) {
        const response = await fetch(this.getUrl('subscriptions/billingCycle'), {
            method: 'PATCH',
            headers: this.getRequestHeaders(),
            body: JSON.stringify({ billingCycle: billingcycle }),
        });
        return await this.transformTenantResponse(response);
    }

    async applyCoupon(coupons: string[]) {
        const response = await fetch(this.getUrl('subscriptions/coupons'), {
            method: 'PATCH',
            headers: this.getRequestHeaders(),
            body: JSON.stringify({ coupons }),
        });
        return await this.transformTenantResponse(response);
    }

    // updateBillingCycle(tenant: string, data: {}) {
    //   /** @type {Request<{ data: import('./types')}>} */
    //   const request = new Request()
    //     .setVerb("PATCH")
    //     .setUrl(`${tenant}/subscriptions/billingCycle`)
    //     .addData(data);
    //   // .asForm(true);

    //   return this.client.try(request);
    // }

    async paymentMethodsEdit() {
        try {
            const response = await fetch(this.getUrl('customers/paymentMethods/edit'), {
                method: 'GET',
                headers: this.getRequestHeaders(),
            });
            return (await response.json()) as {
                data: {
                    url: string;
                };
            };
        } catch (error) {}
    }

    async updateSpots(quantity: number): Promise<ModelEndpointResponse<Tenant>> {
        const response = await fetch(this.getUrl('subscriptions/userSpot'), {
            method: 'PATCH',
            headers: this.getRequestHeaders(),
            body: JSON.stringify({ quantity }),
        });
        return await this.transformTenantResponse(response);
    }

    async activate(): Promise<ModelEndpointResponse<Tenant>> {
        const response = await fetch(this.getUrl('subscriptions/status'), {
            method: 'PATCH',
            headers: this.getRequestHeaders(),
            body: JSON.stringify({ status: 'active' }),
        });
        return await this.transformTenantResponse(response);
    }

    async getInvoices(): Promise<any> {
        const response = await fetch(this.getUrl('invoices'), {
            method: 'GET',
            headers: this.getRequestHeaders(),
        });
        const json = await response.json();

        if (json.data) {
            return json.data.map((invoice: any) => {
                return new SubscriptionInvoice(invoice);
            });
        }

        return json;
    }

    async downloadInvoice(invoiceId: string): Promise<string | null> {
        try {
            var response = await fetch(this.getUrl(`invoices/${invoiceId}/pdf`), {
                method: 'GET',
                headers: this.getRequestHeaders(),
            });
        } catch (e) {
            console.error(e);
            return null;
        }

        const json = await response.json();

        if (json.data && json.data.download_url) {
            return json.data.download_url;
        }

        return null;
    }

    trackEvent(event: string, data: Object): void {
        data = { ...data, ...{ endpoint: this.constructor.name } };

        const { $trackEvent } = useNuxtApp();
        $trackEvent(event, data);
    }
}
