import moment, { type MomentInstance } from '~~/utils/moment';
import camelCaseKeys from '~~/utils/camelCaseKeys';

export default class SubscriptionInvoice {
    attributes: Record<string, any>;

    constructor(attributes: Record<string, any>) {
        this.attributes = camelCaseKeys(attributes);
    }

    get id(): string {
        return this.attributes.id;
    }

    get date(): MomentInstance {
        return moment.unix(this.attributes.date);
    }

    get status(): string {
        return this.attributes.status;
    }

    get total(): number {
        return this.attributes.total;
    }

    get totalPaid(): number {
        return this.attributes.amountPaid;
    }

    get totalDue(): number {
        return this.attributes.amountDue;
    }
}
